import React from 'react';

function Conference() {
	return (
		<section id="conference" className="py-5 bg-dark">
			<div className="container">
				<div className="row h-100">
					<div className="col-md-6 text-light">
						<div className="h-100 d-flex flex-column justify-content-center text">
							<h1 className="fw-semi-bold lh-sm fs-4 fs-lg-5 fs-xl-6 text-light">KONFERENS & FEST</h1>
							<p className="mb-3 fs-1 fw-light">
								Konferens, bröllop, kalas eller lägerverksamhet i Grundsund.
								<br/>
								Vi erbjuder fritidsaktiviteter i en fantastisk miljö
								och samarbetar med restauranger, matställen och caféer.
								<br/>
								Vi ordnar paketlösningar för dina behov!
							</p>
							<p className="mb-4 fs-1 fw-light">
								Ring oss gärna på 0722-44 55 66 / <span className="fst-italic">Olof och Lena Hermansson</span>
							</p>


						</div>
					</div>
					<div className="col-md-6 text-muted">
						<h5 className="text-uppercase text-light">Konferenslokaler</h5>
						<p className="mb-4 fs-0 fw-light">
							Vi har sjöbodar i hamnen med kök och WC för mindre konferenser eller fester.
							<br/>
							Skaftö Folkets Hus har mycket fina lokaler och ligger intill vandrarhemmet.
						</p>

						<h5 className="text-uppercase text-light">Lokaler för idrott</h5>
						<p className="mb-4 fs-0 fw-light">
							100 meter till modern idrottshall med bastu och gym samt en fullskalig fotbollsplan.
						</p>

						<h5 className="text-uppercase text-light">Vi förmedlar</h5>
						<ul className="list-unstyled">
							<li className="fs-0 fw-light">Catering med t ex havsinspirerad mat.</li>
							<li className="fs-0 fw-light">Kajaker, båtar, skärgårdsturer, hummerfiske.</li>
						</ul>

						<b>Obs! Moms tillkommer då företag bokar.</b>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Conference;


import React from 'react';
import trappa from '../images/trappa_mellan_bv_o_ov.jpg';
import {Badge} from 'react-bootstrap';
import {displayList} from "../common/commons";

function Welcome() {
	const [welcomeList, setWelcomeList] = React.useState(null);

	React.useEffect(() => {
		fetch('/db/vandrarhemmet_section.php')
		.then(results => results.json())
		.then(data => setWelcomeList(data ? data : []));
	}, []);

	return (
		<section id="welcome" className="py-0">
			<div className="container">
				<div className="row h-100 g-0">
					<div className="col-lg-6">
						<div className="bg-300 p-4 h-100 d-flex flex-column justify-content-center">
							<h1 className="fw-semi-bold lh-sm fs-4 fs-lg-5 fs-xl-6">Vandrarhemmet</h1>
							<ul className="mb-5 fs-1 fw-light">
								{displayList(welcomeList)}
							</ul>
							<div className="d-grid gap-2 d-md-block">
								<Badge variant="dark" className="text-uppercase m-1 py-2 bg-success fs--2">Gratis parkering</Badge>
								<Badge variant="dark" className="text-uppercase m-1 py-2 bg-success fs--2">Gratis Wi-Fi</Badge>
								<Badge variant="dark" className="text-uppercase m-1 py-2 bg-success fs--2">Öppet Året runt</Badge>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="card card-span h-100 text-white">
							<img className="card-img h-lg-100" src={trappa} alt="..."/>
						</div>
					</div>
				</div>
			</div>

		</section>
	);
}

export default Welcome;
import React from 'react';
import Link from "../components/Link";

function Footer() {
	return (
		<section className="py-0 pt-4" id="contact">
			<div className="container">
				<div className="row text-start">
					<div className="col-6 col-lg-4 mb-3">
						<h5 className="lh-lg fw-bold text-light text-uppercase">Länkar</h5>
						<ul className="list-unstyled mb-md-4 mb-lg-0 text-start">
							<li className="lh-md">
								<Link url="http://alltomlysekil.se/" name="Lysekils Turistsida" className="text-300 text-decoration-none"/>
								<p className="text-600">Allt du behöver veta om Lysekil med omnejd</p>
							</li>
							<li className="lh-md">
								<Link url="http://www.skafto.com/" name="Skaftö Hemsida" className="text-300 text-decoration-none"/>
								<p className="text-600">Skaftö i Lysekils skärgård</p>
							</li>
						</ul>
					</div>
					<div className="col-6 col-lg-4 mb-3">
						<h5 className="lh-lg fw-bold text-light text-uppercase">Kartor</h5>
						<ul className="list-unstyled mb-md-4 mb-lg-0 text-start">
							<li className="lh-md">
								<Link url="../download/Karta_Grundsund_2021-05-28.pdf" name="Karta Grundsund" className="text-300 text-decoration-none"/>
								<p className="text-600">Karta och info om Grundsund</p>
							</li>
							<li className="lh-md">
								<Link url="../download/Gang_och_cykelvagar_pa_Skaftö-21-05.pdf" name="Cykelkarta Skaftö" className="text-300 text-decoration-none"/>
								<p className="text-600">Röda sträck är cykelvägar, gula sträck är cykelbara vägar</p>
							</li>
						</ul>
					</div>
					<div className="col-12 col-lg-4">
						<h5 className="lh-lg fw-bold text-light text-uppercase">Kontakt</h5>
						<p className="text-600 mb-2">
							<svg className="feather feather-phone me-3" xmlns="http://www.w3.org/2000/svg" width="16"
								 height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
								 strokeLinecap="round" strokeLinejoin="round">
								<path
									d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
							</svg>
							<span className="text-300">+46 (0) 722 - 44 55 66</span>
						</p>
						<p className="text-600 mb-2">
							<svg className="feather feather-mail me-3" xmlns="http://www.w3.org/2000/svg" width="16"
								 height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
								 strokeLinecap="round" strokeLinejoin="round">
								<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
								<polyline points="22,6 12,13 2,6"/>
							</svg>
							<span className="text-300">info@skafto.com</span>
						</p>
						<p className="text-600 mb-2">
							<svg className="feather feather-home me-3" xmlns="http://www.w3.org/2000/svg" width="16"
								 height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
								 strokeLinecap="round" strokeLinejoin="round">
								<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
								<polyline points="9 22 9 12 15 12 15 22"/>
							</svg>
							<span className="text-300">Alfågelstigen 4, 45179 Grundsund</span>
						</p>
						<p className="text-600 mb-2">
							<svg className="feather feather-download me-3" xmlns="http://www.w3.org/2000/svg" width="16"
								 height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
								 strokeLinecap="round" strokeLinejoin="round">
								<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
								<polyline points="7 10 12 15 17 10"/>
								<line x1="12" y1="15" x2="12" y2="3"/>
							</svg>
							<span>
								<Link className="text-300" name="Ladda ner Infoblad" url={"../download/Infoblad_Skafto.pdf"}/>
							</span>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Footer;


import React from 'react';
import Link from "../components/Link";
import {displayList} from "../common/commons";

function Grundsund() {
	const [serviceList, setServiceList] = React.useState(null);

	React.useEffect(() => {
		fetch('/db/service_list.php')
		.then(results => results.json())
		.then(data => setServiceList(data ? data : []));
	}, []);

	return (
		<section className="py-0 pb-6" id="grundsund">
			<div className="container">
				<div className="row h-100">
					<h2 className="fw-semi-bold lh-sm fs-4 fs-lg-5 fs-xl-6 text-start pt-6">GRUNDSUND</h2>
					<div className="col-lg-6 text-start pb-6">
						<p>
							Grundsund är ett genuint och idylliskt Bohuslänskt fiskeläge som
							har bevarat den naturliga gamla charmen.
						</p>
						<p>
							Centrum består av ICA-nära som är öppen varje dag. Bredvid ligger
							fiskaffär Krögens Fisk och ett matställe som är öppet nästan året
							runt, Krögens Fisk &amp; Krog. Där finns även en marin - antik- och
							designaffär Butik Rågårdsvik.
						</p>
						<p>
							Går man förbi centrum och passerar bron över havskanalen är man
							på en ö i Grundsund.
						</p>
						<p>
							Till höger efter bron ligger restaurang Smultron &amp;
							Tång <Link name="www.smultrontang.se" url="http://www.smultrontang.se"/> med fantastisk utsikt över Gästhamn
							och Grundsunds hamn.
						</p>
						<p>
							Till vänster efter bron finns Kyrkan,
							Marinan <Link name="www.grundsundsmarina.se" url="http://www.grundsundsmarina.se"/> med Skeppshandel, bastu
							och ett litet café med design-affär, Sommardepån som ligger
							alldeles intill havskanalen.
						</p>
						<p>
							Till höger innan bron (vid kyrkan) följer man havskanalen. Efter att
							ha passerat en stentrappa och en lång brygga ligger klädesaffären
							Didrikssons. Därefter restaurang Pelles
							Rökeri <Link name="www.pellesrokeri.se" url="http://www.pellesrokeri.se"/> med fantastisk utsikt över ”Havets E-
							6:a”.
						</p>
						<p>
							Där börjar Strandpromenaden, en nybyggd brygga som följer
							havskanten norrut och slutar vid en badstrand, Vigerna. Längs hela
							Strandpromenaden finns soffor att sitta på för att njuta av den
							fantastiska havsutsikten. Där finns även badplatser och grillplats.
						</p>
						<p>
							Fortfarande finns här en aktiv fiskeflotta. Du kan köpa nykokta räkor
							och skaldjur direkt från yrkesfiskare. Du kan beställa räkor av
							räktrålaren Atlantic. Hinner du inte till klockan 5 på torsdagar, då
							båten lägger till i Grundsunds hamn, så kan du köpa räkorna från
							deras sjöbod bredvid båten. <Link name="www.atlantic.se" url="http://www.atlantic.se"/>.
							Havskräftor och krabbor kan man köpa av Bobo <Link name="www.bobosfisk.se" url="http://www.bobosfisk.se"/>.
						</p>
						<p>
							Går man upp på något av de stora bergen i samhället ser man ända
							bort till Smögen och Kungshamn och ett öppet hav.
						</p>
						<p>
							På Skaftö spelades Saltön in baserat på Viveca Lärns
							böcker.
						</p>
						<p>
							Grundsund är huvudorten på Skaftö. Här finns förskola, skola
							årskurs 1 till 6, bibliotek, fullskalig fotbollsplan och idrottshall med
							både bastu och gym. Folkets Hus ligger bredvid Vandrarhemmet.
						</p>
					</div>
					<div className="col-lg-6 pb-6 text-start">
						<h5 className="fw-semi-bold lh-sm fs-2">SERVICE</h5>
						<ul>
							{displayList(serviceList)}
						</ul>
					</div>
				</div>
				<div className="row text-900">
					<div className="col-lg-4 text-start">
						<div className="container bg-white p-4 mb-2 mb-lg-0">
							<h5 className="fw-semi-bold lh-sm fs-2">UPPTÄCK</h5>
							<p>Det finns mycket att se och upptäcka!</p>
							<h5 className="fw-semi-bold lh-sm fs-0">Cykla</h5>
							<p>
								Cyklar är gratis ombord om du åker med personfärja till Lysekil.
								Du kan hyra cyklar hos oss på Vandrarhemmet.
							</p>
							<h5 className="fw-semi-bold lh-sm fs-0">Golf</h5>
							<p>
								Det är 4 km till 18-håls banor som Skaftö Golfklubb.
								Det är 2 km till Näreby Golfbana som är en Pay & Play bana med 9 hål.
							</p>
						</div>
					</div>
					<div className="col-lg-4 text-start">
						<div className="container bg-white p-4 mb-2 mb-lg-0">
							<h5 className="fw-semi-bold lh-sm fs-2">UTANFÖR</h5>
							<h5 className="fw-semi-bold lh-sm fs-0">Städer</h5>
							<ul>
								<li>1,5 timme till Göteborg/Liseberg</li>
								<li>40 min till Uddevalla</li>
								<li>50 min till Lysekil</li>
								<li>1,5 timme till Smögen</li>
							</ul>
							<h5 className="fw-semi-bold lh-sm fs-0">Djurpark</h5>
							<p>
								1,5 timme till Nordens Ark <Link name="www.nordensark.se" url="http://www.nordensark.se"/>,
								bevarar utrotningshotade djur. Har ca 80 arter.
							</p>
							<h5 className="fw-semi-bold lh-sm fs-0">Havets Hus</h5>
							<p>
								Havets Hus i Lysekil ger en unik inblick i det marina livet, kul för vuxna och barn.
								För att komma dit kan du ta personfärjan Carl Wilhelmsson <Link name="www.vasttrafik.se" url="http://www.vasttrafik.se"/>.
							</p>
						</div>
					</div>
					<div className="col-lg-4 text-start">
						<div className="container bg-white p-4 mb-2 mb-lg-0">
							<h5 className="fw-semi-bold lh-sm fs-2">VÄDRET</h5>
							<p>
								Molnen bildas ofta in mot land. Därför har vi fler soltimmar än dem som bor längre in i
								landet. Vi har mera blåst, vilket innebär att mygg och knott försvinner.
							</p>
							<p>
								Tyvärr rapporteras inte vädret korrekt gällande Skaftö. Solen skiner ofta i Grundsund.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
);
}

export default Grundsund;
import React from 'react';
import koket from '../images/kok_pa_vandrarhemmet.jpg';
import verandan from '../images/veranda_med_ramp.jpg';
import {displayList} from "../common/commons";

function Prices() {
	const [priceList, setPriceList] = React.useState(null);
	const [rulesList, setRulesList] = React.useState(null);
	const [cancelationList, setCancelationList] = React.useState(null);
	const [termsList, setTermsList] = React.useState(null);

	React.useEffect(() => {
		// Prices
		fetch('/db/price_list.php')
		.then(results => results.json())
		.then(data => setPriceList(data ? data : []));

		fetch('/db/rules_list.php')
		.then(results => results.json())
		.then(data =>  setRulesList(data ? data : []));

		fetch('/db/cancelation_list.php')
		.then(results => results.json())
		.then(data => setCancelationList(data ? data : []));

		fetch('/db/terms_list.php')
		.then(results => results.json())
		.then(data => setTermsList(data ? data : []));

	}, []);

	return (
		<section id="prices" className="py-0 pb-8">
			<div className="container-fluid container-lg">
				<div className="row h-100 g-2 justify-content-center">
					<div className="col-lg-6 mb-3 mb-md-0 h-100">
						<div className="card card-span h-100">
							<img className="img-card h-100" src={koket} alt="..."/>
							<div className="card-body px-xl-5 px-md-3 pt-0 pb-7">
								<h3 className="fw-bold text-1000 my-sm-5 my-3 text-truncate">PRISER & BOKNING</h3>
								<p className="text-900 text-start">
									Ring oss först. Skicka sedan ett mejl där det står ditt namn, bostadsort, telefonnummer, vilka dagar du vill boka, antal personer och ålder på barnen.
									Då får du en bekräftelse. Med bekräftelsen får du en karta över Grundsund där du tydligt närheten till
									restauranger, affärer och havet, i förhållande till Vandrarhemmet.
									Du får även en cykelkarta över Skaftö samt bilder från Grundsund.
								</p>
								<p className="fw-bold text-uppercase text-start mb-0">Vandrarhemsstandard</p>
								<p className="text-900 text-start">Städning, lakan och frukost ingår inte.</p>
								<ul className="text-start">
									{displayList(priceList)}
								</ul>
								<p className="text-900 text-start">
									Storhelger, Jul. Nyår, Påsk och Midsommar har vi högre priser!
								</p>
								<p className="text-900 text-start fw-bold">
									Moms tillkommer då företag bokar.
								</p>
							</div>
						</div>
					</div>

					<div className="col-lg-6 mb-3 mb-md-0 h-100">
						<div className="card card-span h-100">
							<img className="img-card h-100" src={verandan} alt="..."/>
							<div className="card-body px-xl-5 px-md-3 pt-0 pb-7">
								<h3 className="fw-bold text-1000 my-sm-5 my-3 text-truncate">INFO</h3>

								<p className="fw-bold text-uppercase text-start mb-0">INCHECKNING - UTCHECKNING</p>
								<p className="text-900 text-start mb-2">
								Från 17.00 till sent. Utcheckning senast 10.30
								</p>
								<p className="fw-bold text-uppercase text-start mb-0">VILLKOR</p>
								<div className="text-900 text-start mb-2">
									<ul>
										{displayList(termsList)}
									</ul>
								</div>
								<p className="fw-bold text-uppercase text-start mb-0">REGLER</p>
								<div className="text-900 text-start mb-2">
									<ul>
										{displayList(rulesList)}
									</ul>
								</div>

								<p className="fw-bold text-uppercase text-start mb-0">AVBOKNINGSREGLER</p>
								<div className="text-900 text-start mb-2">
									<ul>
										{displayList(cancelationList)}
									</ul>
								</div>
								<p className="text-900 text-start">
									Ring: 0722-44 55 66, 0702-56 80 28
									<br/>
									Telefontider: 08.00 till 10.00 och 17.00 till 21.00
								</p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	);
}

export default Prices;


import React from 'react';

function SmallItems() {
	return (
		<section className="py-6 bg-dark">
			<div className="container">
				<div className="row">
					<div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
						<h5 className="text-light">Bryggstugan</h5>
						<ul className="list-unstyled text-muted">
							<li>5 bäddar i 2 rum, kök.</li>
							<li>Allrum med bäddsoffa och enkelsäng.</li>
							<li>Stor veranda med bord, stolar och grill samt WC och dusch.</li>
						</ul>
					</div>
					<div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
						<h5 className="text-light">Kaptenhuset</h5>
						<ul className="list-unstyled text-muted">
							<li>10+ sängar i 5 rum och kök med hamnutsikt och 10 meter till havet. Det är ett av de husen i Grundsund som förmedlas av oss. Helt andra priser.</li>
						</ul>
					</div>
					<div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
						<h5 className="text-light">Cykeluthyrning</h5>
						<ul className="list-unstyled text-muted">
							<li>Finns på Vandrarhemmet för 150:- till 200:- dygn.</li>
							<li>Hyr du cykel får du med dig en inplastad cykelkarta över Skaftö.</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SmallItems;


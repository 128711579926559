import React from 'react';
import rum8 from '../images/rum8.jpg';

function Rooms() {
	return (
		<section id="rooms" className="py-0">
			<div className="container">
				<div className="row h-100 g-0">
					<div className="col-lg-6">
						<div className="card card-span h-100 text-white">
							<img className="card-img h-lg-100" src={rum8} alt="..."/>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="bg-300 p-4 h-100 d-flex flex-column justify-content-center">
							<h2 className="fw-semi-bold lh-sm fs-4 fs-lg-5 fs-xl-6">VÅRA RUM</h2>
							<div className="mb-5 fs-1 fw-light">
								<h5 className="text-uppercase">Nedervåningen</h5>
								<ul className="list-unstyled">
									<li>3 separata sovrum med enkel- och dubbelsångar. </li>
									<li>Där finns även kök, WC, dusch. TV i ett rum. </li>
								</ul>

								<h5 className="text-uppercase">Övervåningen</h5>
								<ul className="list-unstyled">
									<li>5 separata sovrum med enkel- och dubbelsängar. WC, dusch.</li>
									<li>Allergiker ? Ingen fara, vi har husdjurs- och rökfritt i alla rum!</li>
								</ul>

								<h5 className="text-uppercase">Standard</h5>
								<ul className="list-unstyled">
									<li>Tänk dig Bed & Breakfast i England. Hotellsängar (Carpe Diem och Hästens) med överkast och prydnadskuddar.</li>
									<li>Här finns inga sovsalar.</li>
									<li>Är ni ett par får ni ett eget rum med dubbelsäng eller 2 enkelsängar.</li>
									<li>Inget av våra rum har WC och dusch på rummet utan det är gemensamma WC och duschar.</li>
								</ul>

								<h5 className="text-uppercase">FÖRMEDLING</h5>
								I Grundsund och på hela Skaftö förmedlar vi rum, lägenheter, gårdsstugor och Kaptenshus i hamnen.
								De har högre priser och hyrs endast ut veckovis på sommaren.
								De används vid Konferenser under vår, höst och vinter.

							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	);
}

export default Rooms;


import React from 'react';
import YoutubeVideo from "../components/YoutubeVideo";

function Videos() {
	return (
		<section className="py-6 bg-white">
			<div className="container">
				<div className="row">
					<div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
						<YoutubeVideo embedId={"4dFb4PXAsO4"}/>
					</div>
					<div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
						<YoutubeVideo embedId={"0_FjZcSVVuU"}/>
					</div>
					<div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
						<YoutubeVideo embedId={"82rhr1b6egE"}/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Videos;


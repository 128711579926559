import React from 'react';
import logo from '../images/sv_logo_dark.png';
import {Navbar, Nav} from 'react-bootstrap';

function Menu() {
	return (
		<Navbar className="fixed-top py-3 d-block" bg="light" expand="lg" fixed="top">
			<div className="container">
				<Navbar.Brand href="#home" className={"logo d-inline-flex mt-lg-n2"}><img src={logo} alt={"Skafto.se"}/></Navbar.Brand>
				<Navbar.Toggle aria-controls="navbarScroll"/>
				<Navbar.Collapse id="navbarScroll" className="border-top border-lg-0 mt-4 mt-lg-0">
					<Nav className="me-auto mb-2 mb-lg-0">
						<Nav.Link className="px-2 fw-medium" href="#welcome">Om oss</Nav.Link>
						<Nav.Link className="px-2 fw-medium" href="#rooms">Rum</Nav.Link>
						<Nav.Link className="px-2 fw-medium" href="#conference">Konferens</Nav.Link>
						<Nav.Link className="px-2 fw-medium" href="#prices">Priser & bokning</Nav.Link>
						<Nav.Link className="px-2 fw-medium" href="#images">Bilder</Nav.Link>
						<Nav.Link className="px-2 fw-medium" href="#grundsund">Grundsund</Nav.Link>
					</Nav>
					<form className="d-flex">
						<svg className="feather feather-phone me-3" xmlns="http://www.w3.org/2000/svg" width="16"
							 height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
							 strokeLinecap="round" strokeLinejoin="round">
							<path
								d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
						</svg>
						<div className="text-dark fw-bold">+46 (0) 722 44 55 66</div>
					</form>
				</Navbar.Collapse>
			</div>
		</Navbar>
	);
}

export default Menu;


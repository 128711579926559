import React from 'react';

import './App.css';
import './scss/theme.scss';
import Menu from "./components/Menu";
import ImageSlide from "./sections/ImageSlide";
import Welcome from "./sections/Welcome";
import Hero from "./sections/Hero";
import Footer from "./sections/Footer";
import Rooms from "./sections/Rooms";
import Conference from "./sections/Conference";
import Prices from "./sections/Prices";
import Grundsund from "./sections/Grundsund";
import SmallItems from "./sections/SmallItems";
import Videos from "./sections/Videos";

function App() {
	return (
		<div className="App">
			<main className="main" id="top">
				{/* MENU */}
				<Menu/>

				{/* HERO */}
				<Hero/>

				{/* WELCOME */}
				<Welcome/>

				{/* SMALL INFO */}
				<SmallItems/>

				{/*ROOMS*/}
				<Rooms/>

				{/*CONFERENCE*/}
				<Conference/>

				{/*PRICES*/}
				<Prices/>

				{/* IMAGE CAROUSEL */}
				<ImageSlide/>

				{/* ABOUT */}
				{/* <About/> */}

				{/*GRUNDSUND*/}
				<Grundsund/>

				{/* VIDEOS*/}
				<Videos/>

				{/* FOOTER */}
				<Footer/>
			</main>
		</div>
	);
}

export default App;
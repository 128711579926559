import React from 'react';
import {Carousel} from 'react-bootstrap';

import image1 from '../images/rum1.jpg';
import image2 from '../images/rum2.jpg';
import image11 from '../images/rum3.jpg';
import image12 from '../images/rum6.jpg';
import image3 from '../images/rum7.jpg';
import image4 from '../images/Fiska_fran_piren-Grundsund.jpeg';
import image5 from '../images/Grundsunds_hamn_med_batar.jpg';
import image6 from '../images/Havstulpaner.jpeg';
import image7 from '../images/Kanalen_i_Grundsund.jpg';
import image8 from '../images/Raktralaren_Sando_i_grundsund.jpeg';
import image9 from '../images/Resturang_Smultran_o_Tang.jpeg';
import image10 from '../images/Strandpromenaden_Grundsund.jpeg';
import image13 from '../images/Ubat_och_CarlWilhelmson.jpg';


type ImageItem = { id: number, image: any, text: string, title?:string };

let imageConfig: ImageItem[] = [
	{id: 1, image: image1, text: ""},
	{id: 2, image: image2, text: ""},
	{id: 11, image: image11, text: ""},
	{id: 12, image: image12, text: ""},
	{id: 3, image: image3, text: ""},
	{id: 4, image: image4, text: ""},
	{id: 5, image: image5, text: ""},
	{id: 6, image: image6, text: ""},
	{id: 7, image: image7, text: ""},
	{id: 8, image: image8, text: ""},
	{id: 9, image: image9, text: ""},
	{id: 10, image: image10, text: "Brygga längst havet, soffor, bad, grillplats", title: "Standpromenaden Grundsund"},
	{id: 13, image: image13, text: "Ta färjan till Lysekil, ca 15 min. www.vasttrafik.se", title: "Färjan Carl Wilhelmson"}
];

function ImageSlide() {

	let items = imageConfig.map(item => {
		const title:any = item.text !== "" ?(
			<Carousel.Caption>
				<h3>{item.title}</h3>
				<p>{item.text}</p>
			</Carousel.Caption>
		) : <div/>;
		return (
		<Carousel.Item key={item.id} className="vh-50 vh-lg-75">
			<img
				className="d-block w-100 slide-fit"
				src={item.image}
				alt={item.text}
			/>
			{title}
		</Carousel.Item>
		)});

	return (
		<section id="images" className="py-0">
			<Carousel id="carouselTestimonials" data-bs-ride="carousel" nextLabel={null} prevLabel={null}>
				{items}
			</Carousel>
		</section>
	);
}

export default ImageSlide;


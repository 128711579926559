import React from 'react';

function Hero() {
	return (
		<section className="py-11 bg-light-gradient border-bottom border-white border-5">
			<div className="bg-holder bg-holder-3 overlay overlay-light"/>
			<div className="container">
				<div className="row flex-center">
					<div className="col-12 mb-10">
						<div className="d-flex align-items-center flex-column">
							<h1 className="fs-4 fs-lg-8 fs-md-6 fw-bold">SKAFTÖ VANDRARHEM</h1>
							<h5 className="fw-normal fst-italic">
								Skaftö Vandrarhem i Grundsund. Ett fiskeläge där TV-serien Saltön spelas in.<br/>
								Gå till havet, restauranger, caféer och ICA-butiken.
								Boka weekend, semester, konferenser, fester och bröllop.<br/>
								Vi förmedlar rum, stugor och Kaptenhus. Öppet året runt.<br/>
							</h5>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Hero;

